<template>
  <base-section id="theme-features">
    <base-section-heading
      title="О нас"
    >
      Бренд Айтимус родился недавно, но наша команда сплоченно существует уже более 7 лет. В нашей команде работают эксперты с более чем 20-летним стажем в ИТ. Мы трезво смотрим в будущее, здраво оцениваем потребности и предлагаем решения, которые не только актуальны сегодня, но будут эффективны завтра. Мы следим за тенденциями, используем проверенные и перспективные современные технологии, которые помогают бизнесу зарабатывать.
    </base-section-heading>

    <v-container v-if="feats">
      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="i"
          cols="12"
          md="6"
        >
          <base-avatar-card
            v-bind="feature"
            align="left"
            horizontal
          >
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis illum veniam cum dolores ratione commodi beatae quas maxime, laboriosam excepturi solut!
          </base-avatar-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      feats: false,
      features: [
        {
          title: 'Pixel Perfect Design',
          icon: 'mdi-fountain-pen-tip',
        },
        {
          title: 'Retina Ready',
          icon: 'mdi-cellphone',
        },
        {
          color: 'primary',
          dark: true,
          title: 'Easily Customizable',
          icon: 'mdi-pencil-box-outline',
        },
        {
          title: 'Image Parallax',
          icon: 'mdi-image-size-select-actual',
        },
        {
          title: 'Seo Optimized',
          icon: 'mdi-ice-pop',
        },
        {
          title: '24/7 Support',
          icon: 'mdi-help-circle-outline',
        },
      ],
    }),
  }
</script>
